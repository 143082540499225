import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import LinkListHome from "../components/link-list-home"
import SEO from "../components/seo"
import Content from '../components/content'

export const pageQuery = graphql`
  query HomeQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
		aboutDescription
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 400, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
		articleInformation
		news
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
	return (
		<Layout>
      <SEO/>
      <div className="home-banner grids col-1 sm-2">
        <div>
          {Image ? (
            <Img 
              fluid={Image} 
              alt={frontmatter.title + ' - Featured image'}
              className="featured-image"
			  fadeIn={false}
            />
          ) : ""}
        </div>
        <div>
		  <div className="site-title"><img src="/assets/erabumuni_logo1.gif" alt="しまむに宝箱"/></div>
          <div className="description">{frontmatter.aboutDescription}</div>
		  <div className="about-button">
			<Link to="/about/shimamuni" className="button">しまむにって？</Link>
			<Link to="/about/endangered_lang" className="button btn-secondary">危機言語とは</Link>
		  </div>
        </div>
      </div>
	  <div>
		<Content source={frontmatter.articleInformation} className='information-card' />
      </div>
	  <div>
		<Content source={frontmatter.news} className='information-card' />
      </div>
      <LinkListHome/>
		</Layout>
	)
}

export default HomePage
