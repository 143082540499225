import React from "react"

import LinkCard from "./link-card"

const MenuItems = [
  {
    path: "/articles",
    title: "記事",
	image: "/assets/article.jpg",
	description: "しまむにに関する情報、読み物をまとめています。"
  },
  {
    path: "/materials",
    title: "教材",
	image: "/assets/material.jpg",
	description: "しまむにを学ぶための教材です。「概論」「音」「語と活用」「文法」「場面別の会話」「子供向けの教材」に分かれています。"
  },
  {
    path: "/database",
    title: "語彙データベース",
	image: "/assets/database.jpg",
	description: "しまむにの語彙データベースです。単語、発音記号、例文、音声を収録しています。"
  },
  {
    path: "/links",
    title: "リンク集",
	image: "/assets/link.jpg",
	description: "しまむに関連のリンクをまとめています。"
  },
  {
    path: "/videos",
    title: "動画集",
	image: "/assets/videos.png",
	description: "しまむにの動画へのリンクをまとめています。"
  },
]

const ListMaker = ({ data}) => (
  <section className="home-posts">
    <div className="grids col-1 sm-2 lg-3">
      {data}
    </div>
  </section>
)


class ListLinkHome extends React.Component {

  render () {
    const listMenuItems = MenuItems.map((menuItem, index) =>
	  <LinkCard key={index} data={menuItem}/>
    )
    return <ListMaker data={listMenuItems} />
  }
}

export default ListLinkHome
