import React from "react"
import { Link } from "gatsby"

const LinkCard = ({ data }) => (
  <article className="post-card">
    <Link to={data.path}>
	  <img src={data.image} alt={data.title + ' - Featured image'} className="featured-image"/>
		<div className="post-content">
    	  <h2 className="title"><Link to={data.path}>{data.title}</Link></h2>
    	  <p className="meta">{data.description}</p>
    	</div>
    </Link>
  </article>
)

export default LinkCard
